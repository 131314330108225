import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { auth } from "./services/auth0.services";
import { AUTH0_LOGOUT_URI, AUTH_CLIENT_ID } from "../utils/config";
import { DataContext } from "../utils/ContextProider";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "10%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function PrimarySearchAppBar({ setIsAuthenticated }) {
  const {
    loginUserName,
    isThemeSettingOpen,
    setIsThemeSettingOpen,
    currentColor,
    ChangeThemeColor,
    
  } = React.useContext(DataContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const logout = () => {
    document.cookie =
      "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie =
      "other_cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // navigate
    navigate("/");
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>

      <Box
        sx={{
          borderTop: "2px solid lightgray",
          borderBottom: "2px solid lightgray",
          margin: "4px",
          color: "black",
          display: "flex",
          flexDirection: "column",
          padding: "15px",
        
        }}
      >
        <Typography sx={{ marginBottom: "5px" }}>Select Theme</Typography>
        <Box
          sx={{
            display: "flex",
            gap: "6",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: "2em",
              width: "2em",
              bgcolor: "lightgray",
              borderRadius: "50%",
              margin: "2px",
              padding: "2px",
              cursor: "pointer",
            }}
            onClick={() => ChangeThemeColor(0)}
          ></Box>
          <Box
            onClick={() => ChangeThemeColor(1)}
            sx={{
              height: "2em",
              width: "2em",
              bgcolor: "#faa307",
              borderRadius: "50%",
              margin: "2px",
              padding: "2px",
              cursor: "pointer",
            }}
          ></Box>
          <Box
            sx={{
              height: "2em",
              width: "2em",
              bgcolor: "#003566",
              borderRadius: "50%",
              margin: "2px",
              padding: "2px",
              cursor: "pointer",
            }}
            onClick={() => ChangeThemeColor(2)}
          ></Box>
          <Box
            onClick={() => ChangeThemeColor(3)}
            sx={{
              height: "2em",
              width: "2em",
              bgcolor: "#6a994e",
              borderRadius: "50%",
              margin: "2px",
              padding: "2px",
              cursor: "pointer",
            }}
          ></Box>
        </Box>
      </Box>

      <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: currentColor?.formBackgroundColor,
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            marginX: { xs: "0em", sm: "0em", md: "4.2em", lg:"0.1em" },
          
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 0, color: currentColor.backgroundColor,}}
          >
            <AddShoppingCartIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              display: {
               
                color: currentColor.backgroundColor,
              },
            }}
          >
            Expense App 
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{display:"flex" , gap:"5px"}}> 

          <p style={{color:"black"}}> <span>Welcome </span>{loginUserName}</p>
     
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle
                sx={{
                  color: currentColor.buttonColor,
                }}
              />
            </IconButton>
     
           
          </Box>
        
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
