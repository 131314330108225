import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Link } from "@mui/material";
import { DataContext } from "../utils/ContextProider";
import { sidebarCategories } from "../utils/constant";
import { useNavigate } from "react-router-dom";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import MenuIcon from "@mui/icons-material/Menu";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const Sidebar = () => {
  const { currentColor, selectedCategory, setselectedCategory, setColor } =
    useContext(DataContext);
  const navigate = useNavigate();
  const [CollapsableIcon, setCollapsableIcon] = useState(false);
  const [visibility, setvisibility] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  
  const changeRoute = (category, url) => {
    setselectedCategory(category);
    localStorage.setItem("selectedCategory" ,category)
    //setColor();
    navigate(url);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue); // Update the selected tab index when it changes
  };

  return (
    <Box
      sx={{
        bgcolor: currentColor.formBackgroundColor,
        position: "absolute",
        height: "91.2vh",
       
       
      }}
    >
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "inline" },
         
        }}
      >
        <MenuIcon
          sx={{
            color: "black",
            fontSize: "30px",
            cursor: "pointer",
            transform: CollapsableIcon ? "rotate(180deg)" : "",
            m: "0.1em 0.44em",
          }}
          onClick={(e) => setCollapsableIcon((prev) => !prev)}
        />

        {sidebarCategories.map((category, idx) => (
          <Link
            key={idx}
            onClick={() => changeRoute(category.name, category.url)}
            sx={{
              margin: "1em",
              backgroundColor:
                selectedCategory === category.name && currentColor.buttonColor,
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            
                gap: 2,
              padding: "0.5em",
              color: selectedCategory === category.name ? "white" : "black",
              cursor: "pointer",
              textDecoration: "none",
              borderRadius: "7px",
              marginTop: "2em",
              border: "0.2px solid  gray",
              transition: "background-color ease-in 0.2s",
              "&:hover": {
                backgroundColor:
                  selectedCategory === category.name
                    ? ""
                    : currentColor.tableHeadBackgroundColor,
              },
            }}
          >
            <span>{category.icon}</span>
            {!CollapsableIcon ? (
              <Typography
                sx={{
                  fontWeight: "600px",
                  display: visibility ? "" : "none",
                  fontSize: "18px",
                }}
              >
                {category.name}
              </Typography>
            ) : (
              ""
            )}
          </Link>
        ))}
      </Box>

      <Box
        sx={{
          position: "fixed",
          zIndex: "10",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "white",
          width: "100%",
          display: { xs: "flex", sm: "flex", md: "none" },

          justifyContent: "space-around",
          paddingBottom: "10px",
        }}
      >
        {sidebarCategories.map((category, idx) => (
          <Tabs
            key={idx}
            value={selectedTab} // Use the selectedTab state as the value for Tabs
            onChange={handleTabChange}
            aria-label="icon label tabs example"
          >
            <Tab
              label={category.name}
              sx={{ color: "gray", fontSize: "9px" }}
              icon={category.icon}
              onClick={() => changeRoute(category.name, category.url)}
            />
          </Tabs>
        ))}
      </Box>
    </Box>
  );
};

export default Sidebar;
