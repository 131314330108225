import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import AuthenticationPage from "./components/AuthenticationPage";
import Data from "./components/Data";
import { DataContext } from "./utils/ContextProider";
import Pricing from "./components/Pricing";
import Home from "./components/Home";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import { Themes } from "./utils/constant";
import Sidebar from "./components/Sidebar";
import PrimarySearchAppBar from "./components/PrimarySearchAppBar";
import Reports from "./components/Reports";
import Settings from "./components/Settings";
import EmailVerify from "./components/EmailVerify";
import ResetPassword from "./components/ResetPassword";
import ForgetPassword from "./components/ForgetPassword";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/material";

const App = () => {
  // const savedTheme = JSON.parse(localStorage.getItem("colorMode"));

  const location = useLocation();
  const [userData, setUserData] = useState(localStorage.getItem("emailid") || "");
  const [accessToken, setAccessToken] = useState();
  const [isNavbarOpen, setIsnavbarOpen] = useState(true);
  const [isThemeSettingOpen, setIsThemeSettingOpen] = useState([]);
  const [currentColor, setCurrentColor] = useState(Themes[0]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedCategory, setselectedCategory] = useState(localStorage.getItem("selectedCategory")  || " ")
  const [adminData, setAdminData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [signupLoader, setSignupLoader] = useState(false);
  const [jsonResult, setJsonResult] = useState([]);
  const [base64String, setBase64String] = useState();
  const [checked, setChecked] = React.useState(false);
  const [isImageData, SetIsImageData] = React.useState(false);
  const [file, setFile] = useState("billImage");
  const [loginUserName , setLoginUserName]  = useState(localStorage.getItem("username") || " ")


  const ChangeThemeColor = (index) => {
    setCurrentColor(Themes[index]);
  };

  useEffect(() => {}, [accessToken]);


  // Function to check for the presence of the access token in the cookie
  const checkAccessTokenInCookie = () => {
    const cookies = document.cookie.split(";").map((cookie) => cookie.trim());
    for (const cookie of cookies) {
      const [name, value] = cookie.split("=");
      if (name === "access_token") {
        return value;
      }
    }
    return null;
  };

  const user = checkAccessTokenInCookie();
  

  useEffect(() => {
    if (user) {
      const decoded = jwtDecode(user);
      setIsAdmin(decoded.isAdmin || false);
    }
  }, []);

  const shouldRenderSidebar = ["/dashboard", "/settings", "/reports"].includes(
    location.pathname
  );

  const setColor = (color) => {
    setCurrentColor(color);
    //   localStorage.setItem("colorMode", JSON.stringify(color));
  };

  return (
    <>
      <DataContext.Provider
        value={{
          userData,
          setUserData,
          accessToken,
          setAccessToken,
          isNavbarOpen,
          setIsnavbarOpen,
          isThemeSettingOpen,
          setIsThemeSettingOpen,
          currentColor,
          setCurrentColor,
          ChangeThemeColor,
          isAdmin,
          setIsAdmin,
          selectedCategory,
          setselectedCategory,
          adminData,
          setAdminData,
          setColor,
          isLoading,
          setIsLoading,
          signupLoader,
          setSignupLoader,
          jsonResult,
          setJsonResult,
          setJsonResult,
          base64String,
          setBase64String,
          checked,
          setChecked,
          isImageData,
          SetIsImageData,

          file,
          setFile,
          loginUserName,
          setLoginUserName
        }}
      >
        {!user && !shouldRenderSidebar && <ResponsiveAppBar />}
        {user && <PrimarySearchAppBar />}
        {isAdmin && shouldRenderSidebar && <Sidebar />}
        <Box
          sx={{
            marginLeft:
              isAdmin && shouldRenderSidebar
                ? { lg: "12em", md: "0em", sm: "0em" }
                : "0em",
          }}
        >
          <Routes>
            {isAdmin && <Route path="/dashboard" element={<Data />} />}
            {user && <Route path="/dashboard" exact element={<Data />} />}

            <Route path="/auth/*" exact element={<AuthenticationPage />} />
            <Route
              path="/dashboard"
              element={<Navigate replace to="/auth" />}
            />
            <Route path="/" exact element={ user ? <Data/>: <Home />} />
            <Route path="/forget_password" element={<ForgetPassword />} />
            <Route path="/users/:id/verify/:token" element={<EmailVerify />} />
            <Route path="/reset_password/:token" element={<ResetPassword />} />
            {isAdmin && <Route path="/reports" element={<Reports />} />}
            {isAdmin && <Route path="/settings" element={<Settings />} />}
          </Routes>
        </Box>
      </DataContext.Provider>
    </>
  );
};

export default App;
