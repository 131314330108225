// import * as React from "react";
// import Box from "@mui/material/Box";
// import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import ImageIcon from "@mui/icons-material/Image";
// import PdfIcon from "@mui/icons-material/PictureAsPdf";
// import PdfViewer from "./PdfViewer"; // Import your PDF viewer component
// import ImageViewer from "./ImageViewer"; // Import your Image viewer component
// import { Dialog, DialogContent, DialogTitle } from "@mui/material";
// import { DataContext } from "../utils/ContextProider";

// const VISIBLE_FIELDS = [
//   "name",
//   "price",
//   "category",
//   "date",
//   "nickname",
//   "billfile",
// ];

// export default function CustomizedGrid() {
//   const { currentColor } = React.useContext(DataContext);
//   const [apiData, setApiData] = React.useState({ rows: [], columns: [] });
//   const [openViewer, setOpenViewer] = React.useState(false);
//   const [viewerContent, setViewerContent] = React.useState("");
//   const [isPDF, setIsPDF] = React.useState(false);
//   const [startDate, setStartDate] = React.useState(null);
//   const [endDate, setEndDate] = React.useState(null);

//   const checkAccessTokenInCookie = () => {
//     const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
//       const [name, value] = cookie.split("=");
//       acc[name] = value;
//       return acc;
//     }, {});
//     return cookies.access_token;
//   };

//   const ACCESS_TOKEN = checkAccessTokenInCookie();

//   const fetchData = async () => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}api/productsAdmin`,
//         {
//           method: "GET",
//           headers: {
//             Authorization: `Bearer ${ACCESS_TOKEN}`,
//           },
//         }
//       );
//       const details = await response.json();

//       const columns = Object.keys(details[0]).map((field) => {
//         let column = {
//           field,
//           headerName: field.charAt(0).toUpperCase() + field.slice(1),
//           width: 150,
//         };

//         if (field === "id") {
//           column.hide = true; // Hide the 'id' column
//         } else if (field === "name") {
//           column.headerName = "Remarks";
//         } else if (field === "nickname") {
//           column.headerName = "Name";
//         } else if (field === "date") {
//           // Format 'date' to display only the date part
//           column.valueFormatter = (params) =>
//             new Date(params.value).toLocaleDateString();
//         } else if (field === "billfile") {
//           column.headerName = "Bills";
//           column.renderCell = (params) => {
//             const billfile = params.value;

//             if (billfile && billfile.startsWith) {
//               if (billfile.startsWith("J")) {
//                 // Show PDF icon with click functionality to open PDF viewer
//                 return (
//                   <div
//                     style={{
//                       display: "flex",
//                       alignItems: "center",
//                       cursor: "pointer",
//                     }}
//                   >
//                     <PdfIcon
//                       onClick={() => {
//                         setViewerContent(billfile); // Set the content for the viewer
//                         setIsPDF(true); // Set flag to indicate PDF viewer
//                         setOpenViewer(true); // Open the viewer dialog
//                       }}
//                     />
//                   </div>
//                 );
//               } else if (billfile.startsWith("/") || billfile.startsWith("i")) {
//                 // Show ImageIcon with click functionality to open Image viewer
//                 return (
//                   <div
//                     style={{
//                       display: "flex",
//                       alignItems: "center",
//                       cursor: "pointer",
//                     }}
//                   >
//                     <ImageIcon
//                       onClick={() => {
//                         setViewerContent(billfile); // Set the content for the viewer
//                         setIsPDF(false); // Set flag to indicate Image viewer
//                         setOpenViewer(true); // Open the viewer dialog
//                       }}
//                     />
//                   </div>
//                 );
//               } else {
//                 // Default rendering if conditions are not met
//                 return <div>{billfile}</div>;
//               }
//             } else {
//               // Handle the case when billfile is null or undefined
//               return <div>N/A</div>; // Or any other fallback value/message you prefer
//             }
//           };
//         }

//         return column;
//       });

//       setApiData({ rows: details, columns });
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   const handleViewerClose = () => {
//     setOpenViewer(false);
//   };

//   React.useEffect(() => {
//     fetchData();
//   }, []);

//   return (
//     <Box
//       sx={{
//         height: 450,
//         width: "70vw",
//         textAlign: "center",
//         bgcolor: currentColor.formBackgroundColor,
//         color: currentColor.backgroundColor,
//       }}
//     >
//       <h3>Your Team Expense Details</h3>

//       <DataGrid
//         rows={apiData.rows}
//         columns={apiData.columns.filter((column) => !column.hide)}
//         disableColumnFilter
//         disableColumnSelector
//         disableDensitySelector
//         slots={{ toolbar: GridToolbar }}
//         slotProps={{
//           toolbar: {
//             showQuickFilter: true,
//           },
//         }}
//         sx={{ bgcolor: currentColor.formBackgroundColor }}
//       />
//       <Dialog
//         open={openViewer}
//         onClose={handleViewerClose}
//         maxWidth="md"
//         sx={{
//           paddingBottom: "0px",
//           textAlign: "center",
//           height: "100%",
//         }}
//         fullWidth={isPDF}
//       >
//         <DialogTitle sx={{ color: "black", p: "0px" }}></DialogTitle>
//         <DialogContent
//           sx={{
//             padding: "0px",
//           }}
//         >
//           {isPDF ? (
//             <PdfViewer base64String={viewerContent} />
//           ) : (
//             <ImageViewer base64String={viewerContent} />
//           )}
//         </DialogContent>
//       </Dialog>
//     </Box>
//   );
// }

import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ImageIcon from "@mui/icons-material/Image";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import PdfViewer from "./PdfViewer"; // Import your PDF viewer component
import ImageViewer from "./ImageViewer"; // Import your Image viewer component
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { DataContext } from "../utils/ContextProider";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { saveAs } from "file-saver";

const VISIBLE_FIELDS = [
  "name",
  "price",
  "category",
  "date",
  "nickname",
  "billfile",
];

export default function CustomizedGrid() {
  const [totalPrice, setTotalPrice] = React.useState(0);
  const { currentColor } = React.useContext(DataContext);
  const [apiData, setApiData] = React.useState({ rows: [], columns: [] });
  const [openViewer, setOpenViewer] = React.useState(false);
  const [viewerContent, setViewerContent] = React.useState("");
  const [isPDF, setIsPDF] = React.useState(false);
  const [startDate, setStartDate] = React.useState(
    dayjs().subtract(6, "month")
  );
  const [endDate, setEndDate] = React.useState(dayjs());
  const [endDateToSearch, setEndDateToSearch] = React.useState(endDate);

  const checkAccessTokenInCookie = () => {
    const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.access_token;
  };

  const ACCESS_TOKEN = checkAccessTokenInCookie();

  const fetchData = async () => {
    try {
      const queryParams = new URLSearchParams();

      if (startDate) {
        queryParams.append("startDate", startDate.toISOString());
      }

      if (endDate) {
        // Adjusted end date to include the selected end date
        const adjustedEndDate = new Date(endDate);
        adjustedEndDate.setDate(adjustedEndDate.getDate());
        queryParams.append("endDate", adjustedEndDate.toISOString());
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL
        }/api/productsAdmin?${queryParams.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
        }
      );

      const details = await response.json();

      const sumOfAmount = details.reduce((acc, row) => acc + row.Amount, 0);
      console.log("This is the Response-->", sumOfAmount);
      setTotalPrice(sumOfAmount);

      const columns = Object.keys(details[0]).map((field) => {
        let column = {
          field,
          headerName: field.charAt(0).toUpperCase() + field.slice(1),
          width: 150,
        };

        if (field === "id") {
          column.hide = true; // Hide the 'id' column
        } else if (field === "name") {
          column.headerName = "Remarks";
        } else if (field === "nickname") {
          column.headerName = "Name";
        } else if (field === "date") {
          // Format 'date' to display only the date part
          column.valueFormatter = (params) =>
            new Date(params.value).toLocaleDateString();
        } else if (field === "billfile") {
          column.headerName = "Bills";
          column.renderCell = (params) => {
            const billfile = params.value;

            if (billfile && billfile.startsWith) {
              if (billfile.startsWith("J")) {
                // Show PDF icon with click functionality to open PDF viewer
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <PdfIcon
                      onClick={() => {
                        setViewerContent(billfile); // Set the content for the viewer
                        setIsPDF(true); // Set flag to indicate PDF viewer
                        setOpenViewer(true); // Open the viewer dialog
                      }}
                    />
                  </div>
                );
              } else if (billfile.startsWith("/") || billfile.startsWith("i")) {
                // Show ImageIcon with click functionality to open Image viewer
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <ImageIcon
                      onClick={() => {
                        setViewerContent(billfile); // Set the content for the viewer
                        setIsPDF(false); // Set flag to indicate Image viewer
                        setOpenViewer(true); // Open the viewer dialog
                      }}
                    />
                  </div>
                );
              } else {
                // Default rendering if conditions are not met
                return <div>{billfile}</div>;
              }
            } else {
              // Handle the case when billfile is null or undefined
              return <div>N/A</div>; // Or any other fallback value/message you prefer
            }
          };
        }

        return column;
      });

      setApiData({ rows: details, columns });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleViewerClose = () => {
    setOpenViewer(false);
  };

  const handleBlur = (date) => {
    setEndDateToSearch(date);
    // Additional validation or processing can be added here if needed
    console.log("Selected end date for search:", date.toISOString());
  };

  React.useEffect(() => {
    fetchData();
  }, [endDate]);

  return (
    <Box
      sx={{
        height: { xs: "74vh", sm: "75vh", md: "50vh", lg: "60vh" },
        width: { xs: "93vw", sm: "93vw", md: "90vw", lg: "72vw" },

        textAlign: "center",
        bgcolor: currentColor.formBackgroundColor || "gray",
        color: currentColor.backgroundColor,
        borderRadius: "5px"


      }}
    >
      <Box
        sx={{
          padding: "20px 25px",
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>Your Team Expense Details</h2>
        <Box
          sx={{ display: "flex", gap: "10px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              format="MM/DD/YYYY"
              required
              disableFuture
              sx={{ mr: "0.5vw" }}
            />
            <DatePicker
              label="Date"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              format="DD/MM/YYYY"
              inputProps={{ height: "10px" }}
              required
              disableFuture
            />
          </LocalizationProvider>
        </Box>
      </Box>
      {/* <DataGrid
        rows={apiData.rows}
        columns={apiData.columns.filter((column) => !column.hide)}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        sx={{ bgcolor: currentColor.formBackgroundColor }}
        // Add the footerCellValue prop to customize the footer content
      /> */}

      <Box sx={{ maxHeight: "50vh", overflowY: "scroll" }}>
        <DataGrid
          rows={apiData.rows}
          autoHeight
  
          columns={apiData.columns.filter((column) => !column.hide)}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
            },
          }}
          sx={{ bgcolor: currentColor.formBackgroundColor,px:"15px" }}
          getExportFileBlob={(params) => {
            // Filter out the 'billfile' column from the exported columns
            const filteredColumns = params.columns.filter(
              (column) => column.field !== "billfile"
            );

            // Create a copy of the rows with only the selected columns
            const filteredRows = params.rows.map((row) =>
              filteredColumns.reduce((filteredRow, column) => {
                filteredRow[column.field] = row[column.field];
                return filteredRow;
              }, {})
            );

            // Modify the 'Bills' column content for the exported rows
            const modifiedRows = filteredRows.map((row) => {
              if (
                row.billfile == null ||
                row.billfile === "N/A" ||
                row.billfile === undefined
              ) {
                row.Bills = "N/A";
              }
              return row;
            });

            // Create a new params object with the modified rows and columns
            const modifiedParams = {
              ...params,
              rows: modifiedRows,
              columns: filteredColumns,
            };

            // Use the default export logic with the modified params
            return params.api.getExportBlob(modifiedParams);
          }}
        />
      </Box>

      <Dialog
        open={openViewer}
        onClose={handleViewerClose}
        maxWidth="md"
        sx={{
          paddingBottom: "0px",
          textAlign: "center",
          height: "100%",
        }}
        fullWidth={isPDF}
      >
        <DialogTitle sx={{ color: "black", p: "0px" }}></DialogTitle>
        <DialogContent
          sx={{
            padding: "0px",
          }}
        >
          {isPDF ? (
            <PdfViewer base64String={viewerContent} />
          ) : (
            <ImageViewer base64String={viewerContent} />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
