import React from "react";
import { Box, Button, Typography } from "@mui/material";
import expenseDashboard from "../Images/ex.png";

const Home = () => {
  
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          height: "87vh",
        

        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: {
              xs: "94vw",
              sm: "90vw",
              md: "90vw",
              lg: "50vw",
              xl: "50vw",
            },
            color: "white",
            ml: { xs: "1em", sm: "1em", md: "2.8em" },
            mt: { xs: "1em", sm: "0em" },
       
          }}
        >
          <Typography
            variant="h1"
            sx={{
              color: "orange",
              fontSize: { xs: "2em", sm: "3em", md: "2.5em", lg: "3em", xl: "3.25em" },
              fontWeight: "600",
              lineHeight: "normal",
              letterSpacing: "-.5px",
              textShadow: "none",
            }}
          >
            Empowering You to <br /> Control Your Finances.
          </Typography>

          <Typography
            sx={{
              width: { xs: "93.5%", sm: "95%", md: "95%", lg: "95%", xl: "95%" },
              margin: "15px 0px",
              fontSize: { xs: ".8em", sm: "1.2em", md: "1em", lg: "1em", xl: "1em" },
              textAlign: "justify",
              paddingRight: "1em",
            }}
          >
            Empower your financial decisions with our user-centric expense app.
            Take control of your expenses, set your own rules, and effortlessly
            manage your budget. Our feature-rich platform ensures a personalized
            experience, offering tailored insights and strategies for a seamless
            financial journey tailored to your unique needs.
          </Typography>

          <Box
            sx={{
              display: "flex",
              gap: "2em",
              fontSize: ".63em",
             
            }}
          >
            <Button
              variant="contained"
              sx={{
                fontWeight: "600",
                padding:{xs:"8px 8px" ,sm:"8px 8px" , lg:"8px 8px"},
                backgroundColor: "#f95b51",
                "&:hover": { bgcolor: "purple" },
              }}
            >
              Start A free trial
            </Button>
            <Button
              variant="outlined"
              sx={{
                fontWeight: "600",
                padding:{xs:"8px 8px" ,sm:"8px 8px" , lg:"8px 8px"},
                color: "white",
                "&:hover": { bgcolor: "purple" },
              }}
            >
              Request a Demo
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            width: {xs:"90%" ,sm:"87.5%" , lg:"50%"},
            height: {xs:"45vh" ,sm:"50vh" , lg:"80vh"},
            justifyContent: "center",
            alignItems: {xs:"start" , lg:"center"},
       
            paddingRight:{ lg:"2.7em"},
          }}
        >
          <div
            style={{
              display: "block",
              width: "100%",
              position: "relative",
            
              marginTop: "10px",
            }}
          >
            <img
              src={expenseDashboard}
              alt="dashboard.."
              style={{
                maxWidth: "100%",
                height: "auto",
                borderRadius: "10px",
              }}
            />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default Home;
