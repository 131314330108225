import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import PrimarySearchAppBar from "./PrimarySearchAppBar";
import List from "./List";
import AddExpense from "./AddExpense";
import { DataContext } from "../utils/ContextProider";
import ThemeSettings from "./ThemeSettings";
import SwipeableTemporaryDrawer from "./SwipeableTemporaryDrawer";
import Sidebar from "./Sidebar";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";
import EnhancedTable from "./EnhancedTable";
import { Stack } from "@mui/material";

const Data = () => {
  const { currentColor, setIsLoading, isLoading, isAdmin } =
    useContext(DataContext);
  const [list, setList] = useState([]);
  const location = useLocation();

  const theme = useTheme();
  const shouldRenderSidebar = ["/dashboard", "/settings", "/reports"].includes(
    location.pathname
  );

  const checkAccessTokenInCookie = () => {
    const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.access_token;
  };
  const ACCESS_TOKEN = checkAccessTokenInCookie();

  const fetchData = async () => {
    setIsLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/products`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      }
    );
    const details = await response.json();
    setList(details);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "center",
        gap: 7,
        marginX: { xs: "1em", sm: "3em", md: "2em", lg: "1em" },
        marginTop: { xs: "1.5em", sm: "4em", md: "4em" },
        marginBottom: { xs: "7em", sm: "1em", md: "0em" },
        zIndex: "1",
      }}
    >
      <AddExpense list={list} setList={setList} />{" "}
      <EnhancedTable list={list} setList={setList} />
    </Box>
  );
};

export default Data;
