import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import Paper from "@mui/material/Paper";
import { DataContext } from "../utils/ContextProider";
import { Box, Button, Typography } from "@mui/material";
import AlertDelete from "./AlertDelete";
import Loading from "./Loading";

export default function AdminTable() {
  const { userData, currentColor, adminData, setAdminData  ,} =
    React.useContext(DataContext);
 
  console.log(" ffff",userData)
     
  const [isDelete, setIsDelete] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const [dataToDelete, setIsDataToDelete] = React.useState({
    name: "",
    id: "",
  });

  // fetch data from backend of admin
  const checkAccessTokenInCookie = () => {
    const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.access_token;
  };
  const ACCESS_TOKEN = checkAccessTokenInCookie();

  const fetchData = async () => {
    const checkingAdmin = await fetch(
      `${process.env.REACT_APP_API_URL}/api/admindata`,
      {
        headers: {
          authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      }
    ).then((res) => res.json());

    setAdminData(checkingAdmin);
  };

  const handleDeleteOpen = (id, name) => {
    setIsDelete(true);
    setIsDataToDelete({ name: name, id: id });
  };
  const handleAddOpen = (id, name) => {
    setIsDelete(true);
    setIsDataToDelete({ name: name, id: id });
  };
  // fetchData();
  React.useEffect(() => {
    const fetchDataAsync = async () => {
      setIsLoading(true);
      await fetchData();
      setIsLoading(false);
    };

    fetchDataAsync();
  }, [isDelete]);

  return (
    <Box
      sx={{
        bgcolor: currentColor.formBackgroundColor,
        width: {xs:"45em" , sm :"45em" , lg:"52em"},
        borderRadius: "5px",
        color: currentColor.backgroundColor,
        textAlign: "center",
        maxHeight: "320px",
        minHeight: "320px",
        px:"10px" , 
        maxWidth: { xs: "22em", sm: "100%", md: "100%" },
      }}
    >
      {isLoading ? (
        // Render a loading indicator or message
        <Loading />
      ) : (
        <>
          <TableContainer sx={{ maxHeight: "320px", minHeight: "320px" }}>
            <h2>Other Admin Details</h2>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* <TableCell style={{ fontWeight: "bold" }}>No.</TableCell> */}
                  <TableCell style={{ fontWeight: "bold" }}>Name </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Email Id
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Action
                  </TableCell>
                  {/* <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {adminData.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {/* <TableCell>{row.id}</TableCell> */}
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center" sx={{ cursor: "pointer" }}>
                      {userData && userData === row.email ? (
                        <DeleteIcon sx={{ color: "gray", cursor: "default" }} />
                      ) : (
                        <DeleteIcon
                          onClick={() => {
                            handleDeleteOpen(row.id, row.name);
                          }}
                          sx={{ color: currentColor.buttonColor ,  }}
                          aria-disabled
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {isDelete && (
            <AlertDelete
              isDelete={isDelete}
              setIsDelete={setIsDelete}
              dataToDelete={dataToDelete}
            />
          )}
        </>
      )}
    </Box>
  );
}
