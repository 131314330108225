import React from "react";
import UserDataTable from "./UserDataTable";
import { Box } from "@mui/material";

const Reports = () => {
  return (
    <Box
      sx={{
        height: "80vh",
        display: "flex",
        justifyContent: "center",

        alignItems: "center",
      }}
    >
      <UserDataTable />
    </Box>
  );
};

export default Reports;
