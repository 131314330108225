import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext } from "react";
import AdminTable from "./AdminTable";
import { DataContext } from "../utils/ContextProider";
import AddAdmin from "./AddAdmin";

const Settings = () => {
  const { currentColor } = useContext(DataContext);

  return (
    <Box
      sx={{
        height: "80vh",
        display: "flex",
        flexDirection: { xs: "column", sm: "column", md: "row" ,  lg:"row"
         },
        alignItems: "center",
        justifyContent: "space-evenly",
        gap: { xs: 2, sm: 2, md: 6, lg: 2 },
     
      }}
    >

      <AddAdmin />
      <AdminTable />

    </Box>
  );
};

export default Settings;
